/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface NotificationTypeFieldsOneOf { 
    id?: boolean;
    name?: boolean;
    namePlural?: boolean;
    nameEN?: boolean;
    namePluralEN?: boolean;
    tooltip?: boolean;
    tooltipEN?: boolean;
}

