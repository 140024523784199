/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<ItemPicture>, schemaOptions: { partial: true })
 */
export interface ItemPicturePartial { 
    id?: string;
    idItem?: number;
    originalName?: string;
    ext?: string;
    creationDate?: string;
    visible?: boolean;
    pictureOrder?: number;
}

