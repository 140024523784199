/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Item } from './item';
import { ItemBidding } from './itemBidding';
import { ItemClosing } from './itemClosing';


export interface AuctionActiveUser { 
    userId: string;
    titleBefore?: string;
    firstName: string;
    lastName: string;
    titleAfter?: string;
    email: string;
    phone?: string;
    canvasId?: number;
    allowUploadToCanvas?: boolean;
    language?: string;
    city?: string;
    zipCode?: string;
    newClient?: boolean;
    limits?: Array<ItemBidding>;
    uniqueLimits?: Array<ItemBidding>;
    bids?: Array<ItemClosing>;
    uniqueBids?: Array<ItemClosing>;
    wins?: Array<Item>;
}

