/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';


/**
 * (tsType: FinContractVersionWithRelations, schemaOptions: { includeRelations: true })
 */
export interface FinContractVersionWithRelations { 
    id?: string;
    version?: number;
    filename?: string;
    createdAt?: string;
    content: string;
    finContractId?: string;
    creatorId?: string;
    creator?: MyUserWithRelations;
    foreignKey?: any | null;
}

