/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface EmailTemplate { 
    id?: number;
    code: string;
    description?: string;
    parametrDescription?: string;
    subject: string;
    bodyText: string;
    bodyHtml: string;
    subjectEN: string;
    bodyTextEN: string;
    bodyHtmlEN: string;
}

