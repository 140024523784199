/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<Campaign, \'id\'>, schemaOptions: { title: \'NewCampaign\', exclude: [ \'id\' ] })
 */
export interface NewCampaign { 
    title: string;
    code: string;
    dateStart?: string | null;
    dateEnd?: string | null;
    active: boolean;
    partnerId?: string;
}

