import { Injectable } from '@angular/core';
import {LoginRequest, UserControllerService} from '../../../@backend';
import {LocalUser} from '../models/localUser';
import { jwtDecode } from "jwt-decode";
import {first, firstValueFrom} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUser: LocalUser;
    public isLoggedIn: boolean;

    constructor(private userService: UserControllerService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser){
          this.isLoggedIn = true;
        }else{
          this.isLoggedIn = false;
        }
    }

    public get currentUserValue(): LocalUser {
        return this.currentUser;
    }

    async login(email: string, password: string, auth2FAToken: string | null = null): Promise<{ bLogin: boolean, errorMessage: string | null }>{

      const data = {email, password, auth2FAToken} as LoginRequest;

      try {
        const loginRes = await firstValueFrom(this.userService.userControllerAdminLogin(data));
        if (loginRes.token && loginRes.isAdmin) {
          const decodedJWT = jwtDecode(loginRes.token) as any;
          this.currentUser = {
            token: loginRes.token,
            id: decodedJWT.id,
            exp: decodedJWT.exp,
            name: loginRes.name,
            email: decodedJWT.email
          } as LocalUser;
          this.isLoggedIn = true;
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          return {bLogin: true, errorMessage: null};
        }else {
            return {bLogin: false, errorMessage: 'unknown'};
        }
      } catch (e) {
        console.log(e);
        return {bLogin: false, errorMessage: e.message};
      }
    }

    logout(): any {
        localStorage.removeItem('currentUser');
        this.isLoggedIn = false;
        this.currentUser = null;
    }
}
