/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserScopeFilter } from './myUserScopeFilter';
import { MyUserIncludeFilterItems } from './myUserIncludeFilterItems';


export interface MyUserIncludeFilterInner { 
    relation?: MyUserIncludeFilterInner.RelationEnum;
    scope?: MyUserScopeFilter;
}
export namespace MyUserIncludeFilterInner {
    export type RelationEnum = 'country' | 'userCredentials' | 'userDocuments' | 'notifications' | 'approvalRequests' | 'finContracts' | 'finContractVersions' | 'likedItems' | 'followedAuthors' | 'campaigns';
    export const RelationEnum = {
        Country: 'country' as RelationEnum,
        UserCredentials: 'userCredentials' as RelationEnum,
        UserDocuments: 'userDocuments' as RelationEnum,
        Notifications: 'notifications' as RelationEnum,
        ApprovalRequests: 'approvalRequests' as RelationEnum,
        FinContracts: 'finContracts' as RelationEnum,
        FinContractVersions: 'finContractVersions' as RelationEnum,
        LikedItems: 'likedItems' as RelationEnum,
        FollowedAuthors: 'followedAuthors' as RelationEnum,
        Campaigns: 'campaigns' as RelationEnum
    };
}


