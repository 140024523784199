/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: WhyUsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface WhyUsWithRelations { 
    id?: number;
    title: string;
    titleEN: string;
    text: string;
    textEN: string;
    picture: string;
}

