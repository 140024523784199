/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<MyUserUpdate>, schemaOptions: { partial: true })
 */
export interface MyUserUpdatePartial { 
    email?: string;
    firstName?: string;
    lastName?: string;
    titleBefore?: string;
    titleAfter?: string;
    phone?: string;
    street1?: string;
    street2?: string;
    city?: string;
    zipCode?: string;
    idCountry?: number;
    language?: string;
    bankAccNumber?: string | null;
    company?: string;
    ic?: string;
    dic?: string;
    mailAuctionInfo?: boolean;
}

