/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FinPaymentExcludingIdWithRelations } from './finPaymentExcludingIdWithRelations';


/**
 * (tsType: Omit<FinItemWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface FinItemExcludingIdWithRelations { 
    contractOrder: number;
    name: string;
    description?: string | null;
    author?: string | null;
    size?: string | null;
    dating?: string | null;
    signature?: string | null;
    properties?: string | null;
    technique?: string | null;
    createdAt?: string;
    updatedAt?: string;
    dateDeadline: string;
    pricePurchase?: number;
    priceRepurchase?: number;
    feeRepurchase?: number;
    contractId: string;
    payments?: Array<FinPaymentExcludingIdWithRelations>;
}

