/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: AuthorMinFrontendWithRelations, schemaOptions: { includeRelations: true })
 */
export interface AuthorMinFrontendWithRelations { 
    id: number;
    firstname?: string;
    lastname?: string;
    nickname?: string;
    fullname?: string;
    birthday?: string;
    death?: string;
    hasProfile?: boolean;
    followable?: boolean;
    hasMyFollow?: boolean;
}

