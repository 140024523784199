/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';
import { NotificationMessageExcludingIdWithRelations } from './notificationMessageExcludingIdWithRelations';


/**
 * (tsType: Omit<NotificationMessageRecipientWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface NotificationMessageRecipientExcludingIdWithRelations { 
    dateSent: string;
    channelMail: boolean;
    recipientEmail?: string;
    channelWhatsApp: boolean;
    recipientPhone?: string;
    whatsAppMsgSid?: string;
    dateRead?: string | null;
    wasRead: boolean;
    isHidden: boolean;
    dateHidden?: string | null;
    hiddenByAdmin?: boolean | null;
    messageId?: string;
    recipientId?: string;
    message?: NotificationMessageExcludingIdWithRelations;
    foreignKey?: any | null;
    recipient?: MyUserExcludingIdWithRelations;
}

