/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<ItemType, \'id\'>, schemaOptions: { title: \'NewItemType\', exclude: [ \'id\' ] })
 */
export interface NewItemType { 
    typeName: string;
    typeNameEN: string;
}

