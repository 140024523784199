/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CountryExcludingIdWithRelations } from './countryExcludingIdWithRelations';
import { FinContractExcludingIdWithRelations } from './finContractExcludingIdWithRelations';
import { MyUserCredentialsExcludingIdWithRelations } from './myUserCredentialsExcludingIdWithRelations';
import { ApprovalRequestExcludingIdWithRelations } from './approvalRequestExcludingIdWithRelations';
import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';
import { AuthorExcludingIdWithRelations } from './authorExcludingIdWithRelations';
import { FinContractVersionExcludingIdWithRelations } from './finContractVersionExcludingIdWithRelations';
import { NotificationMessageRecipientExcludingIdWithRelations } from './notificationMessageRecipientExcludingIdWithRelations';
import { UserDocumentExcludingIdWithRelations } from './userDocumentExcludingIdWithRelations';
import { CampaignExcludingIdWithRelations } from './campaignExcludingIdWithRelations';


/**
 * (tsType: Omit<MyUserWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface MyUserExcludingIdWithRelations { 
    regNumber?: number;
    email: string;
    titleBefore?: string;
    firstName?: string;
    lastName?: string;
    titleAfter?: string;
    birthday?: string;
    phone?: string;
    street1?: string;
    street2?: string;
    city?: string;
    idCountry?: number;
    zipCode?: string;
    language?: string;
    registrationDate?: string;
    consent1?: boolean;
    consent2?: boolean;
    hasNewsletter?: boolean;
    id1Approved?: boolean;
    id2Approved?: boolean;
    bankAccApproved?: boolean;
    isApproved?: boolean;
    isAdmin?: boolean;
    idGender?: number;
    idGreeting?: number;
    bankAccNumber?: string;
    comment?: string;
    doc1Number?: string;
    doc1ValidTill?: string;
    doc1Issuer?: string;
    doc1IdDocType?: number;
    doc2Number?: string;
    doc2ValidTill?: string;
    doc2Issuer?: string;
    doc2IdDocType?: number;
    idStatus?: number;
    company?: string;
    ic?: string;
    dic?: string;
    isNotPEP?: boolean;
    mailAuctionInfo?: boolean;
    mailAuctionAlert?: boolean;
    notificationSystemWhatsApp?: boolean;
    notificationSystemMail?: boolean;
    notificationAuctionInfoWhatsApp?: boolean;
    notificationAuctionInfoMail?: boolean;
    notificationNewsletterWhatsApp?: boolean;
    notificationNewsletterMail?: boolean;
    notificationFollowedAuthorWhatsApp?: boolean;
    notificationFollowedAuthorMail?: boolean;
    notificationLikedItemWhatsApp?: boolean;
    notificationLikedItemMail?: boolean;
    isReviewer?: boolean;
    canvasId?: number | null;
    allowUploadToCanvas: boolean;
    fullName?: string | null;
    auth2FAEnabled?: boolean;
    auth2FASecret?: string;
    auth2FAIV?: string;
    country?: CountryExcludingIdWithRelations;
    foreignKey?: any | null;
    userCredentials?: MyUserCredentialsExcludingIdWithRelations;
    userDocuments?: Array<UserDocumentExcludingIdWithRelations>;
    notifications?: Array<NotificationMessageRecipientExcludingIdWithRelations>;
    approvalRequests?: Array<ApprovalRequestExcludingIdWithRelations>;
    finContracts?: Array<FinContractExcludingIdWithRelations>;
    finContractVersions?: Array<FinContractVersionExcludingIdWithRelations>;
    likedItems?: Array<ItemExcludingIdWithRelations>;
    followedAuthors?: Array<AuthorExcludingIdWithRelations>;
    campaigns?: Array<CampaignExcludingIdWithRelations>;
}

