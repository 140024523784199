/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<ApprovalRequestWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface ApprovalRequestPartialWithRelations { 
    id?: number;
    idUser?: string;
    creationDate?: string;
    campaignCode?: string;
    campaignId?: string;
    approved?: boolean;
    responseDate?: string;
    responseComment?: string;
}

