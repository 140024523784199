/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemStatusTR } from './itemStatusTR';
import { AuctionStatusTR } from './auctionStatusTR';
import { ItemTypeTR } from './itemTypeTR';


/**
 * (tsType: AllCodeLists, schemaOptions: { includeRelations: false })
 */
export interface AllCodeLists { 
    itemStatus?: Array<ItemStatusTR>;
    auctionStatus?: Array<AuctionStatusTR>;
    itemType?: Array<ItemTypeTR>;
}

