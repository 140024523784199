<!--<mat-toolbar class="border-bottom-ccc" *ngIf="isLoggedIn()">-->
<!--  <button-->
<!--    mat-icon-button-->
<!--    aria-label="Example icon-button with menu icon"-->
<!--    (click)="sidenav.toggle()"-->
<!--  >-->
<!--    <mat-icon>menu</mat-icon>-->
<!--  </button>-->
<!--  <img src="/assets/images/logo.svg" width="100" height="50" />-->
<!--  <span style="padding-left: 10px; padding-top: 10px">IAP </span>-->
<!--  <small style="font-size: 8px; padding-left: 6px; padding-top: 16px">{{-->
<!--    version-->
<!--  }}</small>-->

<!--  <span class="fill-space"></span>-->


<!--  <mat-chip-listbox class="m-5">-->
<!--    <mat-chip-->
<!--      *ngIf="!env.production"-->
<!--      color="accent"-->
<!--      style="color: white; background: #ee3838"-->
<!--      >{{ envName }}</mat-chip-->
<!--    >-->
<!--    <mat-chip-->
<!--      *ngIf="env.production"-->
<!--      color="accent"-->
<!--      style="color: white; background: #0044a9"-->
<!--      >{{ envName }}</mat-chip-->
<!--    >-->
<!--    <mat-chip-->
<!--      *ngIf="isLoggedIn() && authService.currentUser?.name"-->
<!--      color="accent"-->
<!--      style="color: #000000; background: #89ff9a"-->
<!--      [matTooltip]="authService.currentUser?.email"-->
<!--      ><mat-icon>account_box</mat-icon>-->
<!--      {{ authService.currentUser?.name }}</mat-chip-->
<!--    >-->
<!--  </mat-chip-listbox>-->

<!--  <button-->
<!--    *ngIf="!env.production"-->
<!--    mat-icon-button-->
<!--    [cdkCopyToClipboard]="authService.currentUser.token"-->
<!--    matTooltip="Uložení tokenu do schránky"-->
<!--  >-->
<!--    <mat-icon matPrefix>vpn_key</mat-icon>-->
<!--  </button>-->

<!--  <button-->
<!--    *ngIf="!env.production"-->
<!--    mat-icon-button-->
<!--    aria-label="Access token"-->
<!--    (click)="openURL(this.env.baseApiUrl + '/explorer')"-->
<!--    matTooltip="Otevřít explorer"-->
<!--  >-->
<!--    <mat-icon>api</mat-icon>-->
<!--  </button>-->
<!--  <button-->
<!--    mat-icon-button-->
<!--    aria-label="Example icon-button with share icon"-->
<!--    (click)="openURL(this.baseFrontUrl)"-->
<!--    matTooltip="Otevřít hlavní stránku"-->
<!--  >-->
<!--    <mat-icon>launch</mat-icon>-->
<!--  </button>-->
<!--</mat-toolbar>-->

<router-outlet></router-outlet>

