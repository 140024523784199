/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationTemplate } from './notificationTemplate';


export interface NotificationSendCustomParams { 
    recipientIds?: Array<string>;
    channel?: string;
    template: NotificationTemplate;
    to: string;
    idAuction?: number;
    userType?: number;
}

