/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface AddressIap { 
    street1?: string;
    street2?: string;
    city?: string;
    zipCode?: string;
    countryId?: number;
}

