/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FinItemPartialWithRelations } from './finItemPartialWithRelations';
import { FinContractStatePartialWithRelations } from './finContractStatePartialWithRelations';
import { MyUserPartialWithRelations } from './myUserPartialWithRelations';
import { FinPaymentPartialWithRelations } from './finPaymentPartialWithRelations';
import { FinContractVersionPartialWithRelations } from './finContractVersionPartialWithRelations';


/**
 * (tsType: Partial<FinContractWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface FinContractPartialWithRelations { 
    id?: string;
    contractNumber?: string;
    otherArrangements?: string;
    createdAt?: string;
    updatedAt?: string;
    dateDeadline?: string;
    sumPurchase?: number;
    sumRepurchase?: number;
    sumPayment?: number;
    currentVersion?: number;
    userId?: string;
    contractStateId?: string;
    items?: Array<FinItemPartialWithRelations>;
    payments?: Array<FinPaymentPartialWithRelations>;
    user?: MyUserPartialWithRelations;
    foreignKey?: any | null;
    state?: FinContractStatePartialWithRelations;
    versions?: Array<FinContractVersionPartialWithRelations>;
}

