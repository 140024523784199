/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';


/**
 * (tsType: Omit<FinContractVersionWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface FinContractVersionExcludingIdWithRelations { 
    version?: number;
    filename?: string;
    createdAt?: string;
    content: string;
    finContractId?: string;
    creatorId?: string;
    creator?: MyUserExcludingIdWithRelations;
    foreignKey?: any | null;
}

