/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Author, schemaOptions: { includeRelations: false })
 */
export interface Author { 
    id?: number;
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string | null;
    birthday?: string | null;
    death?: string | null;
    hasProfile?: boolean;
    followable?: boolean;
    canvasId?: number | null;
    followersCount?: number;
    created?: string;
}

