/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<TeamMember, \'firstname\' | \'lastname\' | \'titleBefore\' | \'titleAfter\' | \'email\' | \'showMail\' | \'filename\' | \'visible\' | \'maternityLeave\'>, schemaOptions: { title: \'TeamMemberOrderUpdate\', includeRelations: false, exclude: [ \'firstname\', \'lastname\', \'titleBefore\', \'titleAfter\', \'email\', \'showMail\', \'filename\', \'visible\', \'maternityLeave\' ] })
 */
export interface TeamMemberOrderUpdate { 
    id?: number;
    order?: number | null;
}

