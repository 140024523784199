/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Bid } from './bid';


export interface ForceBidRes { 
    status?: object;
    decisionInfo?: object;
    availableBids?: Array<Bid>;
}

