/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface AuctionParticipantsTags { 
    tagWinner?: string;
    tagWinnerDescription?: string;
    tagParticipant?: string;
    tagParticipantDescription?: string;
    tagNewUser?: string;
    tagNewUserDescription?: string;
}

