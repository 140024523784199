/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionLogIncludeFilterItems } from './auctionLogIncludeFilterItems';
import { AuctionLogScopeFilter } from './auctionLogScopeFilter';


export interface AuctionLogIncludeFilterInner { 
    relation?: AuctionLogIncludeFilterInner.RelationEnum;
    scope?: AuctionLogScopeFilter;
}
export namespace AuctionLogIncludeFilterInner {
    export type RelationEnum = 'user';
    export const RelationEnum = {
        User: 'user' as RelationEnum
    };
}


