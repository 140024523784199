/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: CreateItemBidding, schemaOptions: { title: \'CreateItemBidding\' })
 */
export interface CreateItemBidding { 
    idItem: number;
    bid: number;
}

