/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface AdminNewUserReq { 
    email: string;
    titleBefore?: string;
    firstName?: string;
    lastName?: string;
    titleAfter?: string;
    phone?: string;
    street1?: string;
    street2?: string;
    city?: string;
    idCountry?: number;
    zipCode?: string;
    language?: string;
    consent1?: boolean;
    consent2?: boolean;
    hasNewsletter?: boolean;
    idGender?: number;
    idGreeting?: number;
    bankAccNumber?: string;
    company?: string;
    ic?: string;
    dic?: string;
    isNotPEP?: boolean;
    password?: string;
    isReviewer?: boolean;
    isAdmin?: boolean;
    isApproved?: boolean;
    sendResetEmailPassword?: boolean;
    comment?: string;
}

