<section class="bg-gray-900">
<!--<section class="bg-yellow-300 dark:bg-white">-->
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen pb-[15vh]">
    <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
      <img class="w-40 h-20 mr-2" src="/assets/images/logo_white.svg" alt="logo">
    </a>
    <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
      <div class="p-3 space-y-4 md:space-y-6 sm:p-8">
        <h1 class="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          Přihlášení do administrace
        </h1>
        <form class="space-y-4 md:space-y-6" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Váš email</label>
              <input matInput formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autocomplete="username" type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="vase-emailova@schranka.cz" required="">
          </div>
          <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Heslo</label>
            <input matInput formControlName="password" type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
          </div>
          <div *ngIf="isAllowedAuth2FA">
            <label for="auth2FAToken" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ověřovací kód</label>
            <input matInput formControlName="auth2FAToken" maxlength="6" type="text" name="auth2FAToken" id="auth2FAToken" placeholder="••• •••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-start place-content-center text-red text-center">
              {{ErrMessage}}
<!--              <div class="flex items-center h-5">-->
<!--                <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">-->
<!--              </div>-->
<!--              <div class="ml-3 text-sm">-->
<!--                <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>-->
<!--              </div>-->
            </div>
<!--            <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>-->
          </div>
          <button mat-flat-button
                  color="primary" [disabled]="loading"
                  class="w-full text-black bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Přihlásit se
          </button>
<!--          <p class="text-sm font-light text-gray-500 dark:text-gray-400">-->
<!--            Don’t have an account yet? <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>-->
<!--          </p>-->
        </form>
      </div>
    </div>
  </div>
</section>
