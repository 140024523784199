/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationMessageRecipientPartialWithRelations } from './notificationMessageRecipientPartialWithRelations';


/**
 * (tsType: Partial<NotificationMessageWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface NotificationMessagePartialWithRelations { 
    id?: string;
    dateCreated?: string;
    type?: string;
    template?: string;
    subject?: string;
    subjectEN?: string;
    contentShort?: string;
    contentShortEN?: string;
    contentLong?: string;
    contentLongEN?: string;
    recipients?: Array<NotificationMessageRecipientPartialWithRelations>;
}

