/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: ItemClosingStatus, schemaOptions: { includeRelations: false })
 */
export interface ItemClosingStatus { 
    currentPrice: number;
    alreadyBidded: boolean;
    isWinning: boolean;
    idCurrentClosingItem?: number;
    countDown?: number;
    lastClosingTime?: string;
    endClosingTime?: string;
    waitingForDecision: boolean;
    myMaxBid: number;
    lastTempBidWin: boolean;
    preClosingAvailable?: boolean;
}

