/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';
import { AuthorWithRelations } from './authorWithRelations';


/**
 * (tsType: UserAuthorFollowWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UserAuthorFollowWithRelations { 
    id?: number;
    createdAt?: string;
    userId?: string;
    authorId?: number;
    user?: MyUserWithRelations;
    foreignKey?: any | null;
    author?: AuthorWithRelations;
}

