/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';
import { ItemWithRelations } from './itemWithRelations';


/**
 * (tsType: UserItemLikeWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UserItemLikeWithRelations { 
    id?: number;
    createdAt?: string;
    userId?: string;
    itemId?: number;
    user?: MyUserWithRelations;
    foreignKey?: any | null;
    item?: ItemWithRelations;
}

