/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationMessageRecipientWithRelations } from './notificationMessageRecipientWithRelations';


/**
 * (tsType: NotificationMessageWithRelations, schemaOptions: { includeRelations: true })
 */
export interface NotificationMessageWithRelations { 
    id?: string;
    dateCreated: string;
    type: string;
    template: string;
    subject: string;
    subjectEN: string;
    contentShort: string;
    contentShortEN: string;
    contentLong: string;
    contentLongEN: string;
    recipients?: Array<NotificationMessageRecipientWithRelations>;
}

