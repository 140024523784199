/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';


/**
 * (tsType: AuctionLogWithRelations, schemaOptions: { includeRelations: true })
 */
export interface AuctionLogWithRelations { 
    id?: number;
    idItem?: number;
    idUser?: string;
    idExcludedUser?: string;
    logText: string;
    logTextEN: string;
    creationTime?: string;
    user?: MyUserWithRelations;
    foreignKey?: any | null;
}

