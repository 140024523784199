/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemPictureExcludingIdWithRelations } from './itemPictureExcludingIdWithRelations';
import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';
import { ItemTypeExcludingIdWithRelations } from './itemTypeExcludingIdWithRelations';
import { AuthorExcludingIdWithRelations } from './authorExcludingIdWithRelations';
import { ItemBiddingExcludingIdWithRelations } from './itemBiddingExcludingIdWithRelations';
import { ItemClosingExcludingIdWithRelations } from './itemClosingExcludingIdWithRelations';
import { ItemStatusExcludingIdWithRelations } from './itemStatusExcludingIdWithRelations';
import { AuctionExcludingIdWithRelations } from './auctionExcludingIdWithRelations';


/**
 * (tsType: Omit<ItemWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface ItemExcludingIdWithRelations { 
    itemName: string;
    itemNameEN: string;
    description?: string | null;
    descriptionEN?: string | null;
    author?: string | null;
    size?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    priceFinal?: number;
    startPriceForBidding?: number;
    startPriceForClosing?: number;
    idWinningUser?: string;
    idRepresentativeBidder?: string;
    itemOrder?: number;
    withoutFee?: boolean;
    idAuction?: number;
    isHighlight?: number | null;
    dating?: string;
    datingEN?: string;
    signatureCS?: string | null;
    signatureEN?: string | null;
    propertiesCS?: string | null;
    propertiesEN?: string | null;
    winningEmailSent?: number;
    technique?: string | null;
    techniqueEN?: string | null;
    last_modified?: string;
    canvasId?: number | null;
    likesCount?: number;
    originalCatalogNumber?: number | null;
    idType?: number;
    idStatus?: number;
    winner?: MyUserExcludingIdWithRelations;
    foreignKey?: any | null;
    auction?: AuctionExcludingIdWithRelations;
    Type?: ItemTypeExcludingIdWithRelations;
    Status?: ItemStatusExcludingIdWithRelations;
    authors?: Array<AuthorExcludingIdWithRelations>;
    pictures?: Array<ItemPictureExcludingIdWithRelations>;
    limitations?: Array<ItemBiddingExcludingIdWithRelations>;
    closings?: Array<ItemClosingExcludingIdWithRelations>;
    likedBy?: Array<MyUserExcludingIdWithRelations>;
}

