/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface TeamMember { 
    id?: number;
    firstname: string;
    lastname: string;
    titleBefore?: string | null;
    titleAfter?: string | null;
    email?: string;
    showMail?: boolean;
    maternityLeave?: boolean;
    filename?: string | null;
    order?: number | null;
    visible?: boolean;
}

