/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<AuthorLangWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface AuthorLangPartialWithRelations { 
    id?: number;
    authorId?: number | null;
    langId?: number;
    description?: string | null;
}

