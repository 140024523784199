/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<Auction>, schemaOptions: { partial: true })
 */
export interface AuctionPartial { 
    id?: number;
    auctionNumber?: string;
    auctionName?: string;
    auctionNameEN?: string;
    description?: string | null;
    descriptionEN?: string | null;
    descriptionShort?: string | null;
    descriptionShortEN?: string | null;
    internetAuction?: boolean;
    exchangeRate?: number;
    visibleFrom?: string | null;
    visibleTo?: string | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    timeLimit?: number;
    picUrl?: string;
    idCurrentClosingItem?: number;
    fee?: number;
    streamLinkActive?: string;
    streamLinkYouTube?: string | null;
    streamLinkWowza?: string | null;
    biddingTableUrlcs?: string;
    auctionNoticeUrlcs?: string;
    biddingTableUrlen?: string;
    auctionNoticeUrlen?: string;
    toHomePage?: boolean;
    isMainAuction?: boolean;
    last_modified?: string;
    toArchive?: boolean;
    logArchived?: boolean;
    isPaused?: boolean;
    hasStarted?: boolean;
    countDown?: number;
    lastClosingTime?: string | null;
    endClosingTime?: string | null;
    itemMaxBid?: number | null;
    canvasId?: number | null;
    idStatus?: number;
}

