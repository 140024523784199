/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionLogFields } from './auctionLogFields';
import { AuctionLogIncludeFilterInner } from './auctionLogIncludeFilterInner';
import { GenderFilterOrder } from './genderFilterOrder';


export interface AuctionLogFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: GenderFilterOrder;
    fields?: AuctionLogFields;
    include?: Array<AuctionLogIncludeFilterInner>;
}

