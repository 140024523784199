/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionTR } from './auctionTR';


/**
 * (tsType: AuctionTRList, schemaOptions: { includeRelations: false })
 */
export interface AuctionTRList { 
    auctions?: Array<AuctionTR>;
}

