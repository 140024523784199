/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: NewsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface NewsWithRelations { 
    id?: number;
    text?: string;
    textEN?: string;
    showMedia: boolean;
    picture?: string;
    video?: string;
    showNews: boolean;
}

