/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemPictureFieldsOneOf } from './itemPictureFieldsOneOf';


/**
 * @type ItemPictureFields
 * @export
 */
export type ItemPictureFields = ItemPictureFieldsOneOf | Set<string>;

