/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationType } from './notificationType';
import { NotificationMessagePublic } from './notificationMessagePublic';


export interface NotificationLogForUser { 
    types: Array<NotificationType>;
    messages: Array<NotificationMessagePublic>;
    countTotal: number;
    countUnread: number;
}

