<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded-full cursor-pointer relative trans-ease-out select-none py-1 px-1 hover:bg-hover"
     matRipple>
<!--  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{authService.currentUserValue.name}}</div>-->
  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10">
    <mat-icon color="primary" svgIcon="mat:person"></mat-icon>
  </div>
</div>
