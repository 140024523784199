/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FinContractPartialWithRelations } from './finContractPartialWithRelations';


/**
 * (tsType: Partial<FinContractStateWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface FinContractStatePartialWithRelations { 
    id?: string;
    sortOrder?: number;
    title?: string;
    titleEN?: string;
    contracts?: Array<FinContractPartialWithRelations>;
}

