/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<Faq>, schemaOptions: { partial: true })
 */
export interface FaqPartial { 
    id?: number;
    idFaqGroup?: number;
    faqOrder?: number;
    friendlyName?: string;
    friendlyNameEN?: string;
    question?: string;
    questionEN?: string;
    answer?: string;
    answerEN?: string;
}

