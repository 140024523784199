import { BrowserModule } from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {BASE_PATH} from '../@backend';
import { environment } from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RoutingModule} from './routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {SnackBarService} from './shared/services/snack-bar.service';
import {LoginComponent} from './shared/components/login/login.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DndDirective} from './shared/directives/dnd.directive';
import {LiveBiddingService} from './shared/services/liveBidding.service';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {VexModule} from '../@vex/vex.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CustomLayoutModule} from './custom-layout/custom-layout.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { getPaginatorIntl } from './shared/utils/paginator.translate';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';

registerLocaleData(localeCs);

@NgModule({
  declarations: [AppComponent, LoginComponent, DndDirective],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,

    RoutingModule,
    FormsModule,
    ReactiveFormsModule,

    // Material Modules
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatDialogModule,
    FormsModule,
    ClipboardModule,

    // Vex
    VexModule,
    MatSnackBarModule,
    CustomLayoutModule,

    // TODO: DELETE
    MatListModule,
    MatTooltipModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
  ],
  providers: [
    SnackBarService,
    LiveBiddingService,
    { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
    { provide: LOCALE_ID, useValue: 'cs-CZ' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: BASE_PATH, useValue: environment.baseApiUrl },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'Kč' },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
