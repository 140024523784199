/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface MyBidding { 
    id?: number;
    itemOrder?: number;
    idAuction?: number;
    auctionName?: string;
    itemName?: string;
    author?: string;
    authors?: Array<object>;
    myMaxBid?: number;
    bidDate?: string;
    isWinning?: boolean;
    startPriceForBidding?: number;
    currentPrice?: number;
    finalPrice?: number;
    picUrl?: string;
    dating?: string;
    auctionFrom?: string;
    idAuctionStatus?: number;
    auctionStatusCode?: string;
    toArchive?: boolean;
}

