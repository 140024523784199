/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<AuctionLog, \'id\'>, schemaOptions: { title: \'NewAuctionLog\', exclude: [ \'id\' ] })
 */
export interface NewAuctionLog { 
    idItem?: number;
    idUser?: string;
    idExcludedUser?: string;
    logText: string;
    logTextEN: string;
    creationTime?: string;
}

