/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorMinFrontend } from './authorMinFrontend';


export interface ItemNeighborFrontend { 
    id: number;
    itemName: string;
    authors?: Array<AuthorMinFrontend>;
}

