/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface MyBiddingLog { 
    id?: number;
    itemOrder?: number;
    auctionName?: string;
    itemName?: string;
    author?: string;
    authors?: Array<object>;
    bidDate?: string;
    startPriceForBidding?: number;
    startPrice?: number;
    finalPrice?: number;
    bid?: number;
    isWinning?: boolean;
}

