/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FinContractExcludingIdWithRelations } from './finContractExcludingIdWithRelations';


/**
 * (tsType: Omit<FinContractStateWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface FinContractStateExcludingIdWithRelations { 
    sortOrder: number;
    title: string;
    titleEN?: string;
    contracts?: Array<FinContractExcludingIdWithRelations>;
}

