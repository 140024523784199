/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface AuctionControllerResult { 
    iapItemId?: number | null;
    catalogNumber?: number;
    priceHammer?: number;
    iapUserId?: string;
    firstname?: string;
    lastname?: string;
    titleBefore?: string;
    titleAfter?: string;
    phone?: string;
    email?: string;
    addressStreet?: string;
    addressCity?: string;
    addressPostCode?: string;
    addressCountry?: string;
    canvasId?: number;
    allowUpload: boolean;
}

