/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<FinInfo>, schemaOptions: { partial: true })
 */
export interface FinInfoPartial { 
    id?: number;
    profileHeading?: string;
    profileHeadingEN?: string;
    profileDescription?: string;
    profileDescriptionEN?: string;
}

