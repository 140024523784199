/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GenderFilterOrder } from './genderFilterOrder';
import { NewsFields } from './newsFields';


export interface NewsFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: GenderFilterOrder;
    fields?: NewsFields;
}

