/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: FinProfileInfoWithRelations, schemaOptions: { includeRelations: true })
 */
export interface FinProfileInfoWithRelations { 
    heading: string;
    description: string;
}

