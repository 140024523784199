/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<UserNotificationSettings>, schemaOptions: { partial: true })
 */
export interface UserNotificationSettingsPartial { 
    notificationSystemMail?: boolean;
    notificationSystemWhatsApp?: boolean;
    notificationAuctionInfoMail?: boolean;
    notificationAuctionInfoWhatsApp?: boolean;
    notificationFollowedAuthorMail?: boolean;
    notificationFollowedAuthorWhatsApp?: boolean;
    notificationLikedItemMail?: boolean;
    notificationLikedItemWhatsApp?: boolean;
    notificationNewsletterMail?: boolean;
    notificationNewsletterWhatsApp?: boolean;
}

