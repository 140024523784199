/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FinPaymentWithRelations } from './finPaymentWithRelations';


/**
 * (tsType: FinItemWithRelations, schemaOptions: { includeRelations: true })
 */
export interface FinItemWithRelations { 
    id?: string;
    contractOrder: number;
    name: string;
    description?: string | null;
    author?: string | null;
    size?: string | null;
    dating?: string | null;
    signature?: string | null;
    properties?: string | null;
    technique?: string | null;
    createdAt?: string;
    updatedAt?: string;
    dateDeadline: string;
    pricePurchase?: number;
    priceRepurchase?: number;
    feeRepurchase?: number;
    contractId: string;
    payments?: Array<FinPaymentWithRelations>;
}

