/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<ItemPictureWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface ItemPictureExcludingIdWithRelations { 
    idItem?: number;
    originalName?: string;
    ext?: string;
    creationDate?: string;
    visible?: boolean;
    pictureOrder?: number;
}

