/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserPartialWithRelations } from './myUserPartialWithRelations';
import { ItemClosingPartialWithRelations } from './itemClosingPartialWithRelations';
import { AuctionPartialWithRelations } from './auctionPartialWithRelations';
import { ItemPicturePartialWithRelations } from './itemPicturePartialWithRelations';
import { ItemStatusPartialWithRelations } from './itemStatusPartialWithRelations';
import { ItemBiddingPartialWithRelations } from './itemBiddingPartialWithRelations';
import { ItemTypePartialWithRelations } from './itemTypePartialWithRelations';
import { AuthorPartialWithRelations } from './authorPartialWithRelations';


/**
 * (tsType: Partial<ItemWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface ItemPartialWithRelations { 
    id?: number;
    itemName?: string;
    itemNameEN?: string;
    description?: string | null;
    descriptionEN?: string | null;
    author?: string | null;
    size?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    priceFinal?: number;
    startPriceForBidding?: number;
    startPriceForClosing?: number;
    idWinningUser?: string;
    idRepresentativeBidder?: string;
    itemOrder?: number;
    withoutFee?: boolean;
    idAuction?: number;
    isHighlight?: number | null;
    dating?: string;
    datingEN?: string;
    signatureCS?: string | null;
    signatureEN?: string | null;
    propertiesCS?: string | null;
    propertiesEN?: string | null;
    winningEmailSent?: number;
    technique?: string | null;
    techniqueEN?: string | null;
    last_modified?: string;
    canvasId?: number | null;
    likesCount?: number;
    originalCatalogNumber?: number | null;
    idType?: number;
    idStatus?: number;
    winner?: MyUserPartialWithRelations;
    foreignKey?: any | null;
    auction?: AuctionPartialWithRelations;
    Type?: ItemTypePartialWithRelations;
    Status?: ItemStatusPartialWithRelations;
    authors?: Array<AuthorPartialWithRelations>;
    pictures?: Array<ItemPicturePartialWithRelations>;
    limitations?: Array<ItemBiddingPartialWithRelations>;
    closings?: Array<ItemClosingPartialWithRelations>;
    likedBy?: Array<MyUserPartialWithRelations>;
}

