/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemAuction } from './itemAuction';
import { ItemPicUrl } from './itemPicUrl';
import { AuthorMinFrontend } from './authorMinFrontend';


/**
 * (tsType: ItemClosingFrontendWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ItemClosingFrontendWithRelations { 
    id?: number;
    itemName: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    priceFinal?: number;
    priceFinalWithFee?: number;
    startPriceForBidding?: number;
    startPriceForClosing?: number;
    idStatus?: number;
    itemOrder?: number;
    withoutFee?: boolean;
    bidCount?: number;
    hasBid?: boolean;
    hasMyBid?: boolean;
    isBiddingWinning?: boolean;
    isWinning?: boolean;
    hasMyLike?: boolean;
    authors?: Array<AuthorMinFrontend>;
    pictures?: Array<ItemPicUrl>;
    auction?: ItemAuction;
    size?: string;
    properties?: string;
    technique?: string;
    dating?: string;
    signature?: string;
}

