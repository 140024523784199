/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: NotificationTypeWithRelations, schemaOptions: { includeRelations: true })
 */
export interface NotificationTypeWithRelations { 
    id: string;
    name: string;
    namePlural: string;
    nameEN?: string;
    namePluralEN?: string;
    tooltip: string;
    tooltipEN?: string;
}

