/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionLogScopeFilter } from './auctionLogScopeFilter';


export interface AuctionLogIncludeFilterItems { 
    relation?: AuctionLogIncludeFilterItems.RelationEnum;
    scope?: AuctionLogScopeFilter;
}
export namespace AuctionLogIncludeFilterItems {
    export type RelationEnum = 'user';
    export const RelationEnum = {
        User: 'user' as RelationEnum
    };
}


