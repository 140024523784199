/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TeamMemberLangWithRelations } from './teamMemberLangWithRelations';
import { TeamProjectWithRelations } from './teamProjectWithRelations';


/**
 * (tsType: TeamMemberWithRelations, schemaOptions: { includeRelations: true })
 */
export interface TeamMemberWithRelations { 
    id?: number;
    firstname: string;
    lastname: string;
    titleBefore?: string | null;
    titleAfter?: string | null;
    email?: string;
    showMail?: boolean;
    maternityLeave?: boolean;
    filename?: string | null;
    order?: number | null;
    visible?: boolean;
    lang?: TeamMemberLangWithRelations;
    langs?: Array<TeamMemberLangWithRelations>;
    projects?: Array<TeamProjectWithRelations>;
}

