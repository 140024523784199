/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<FaqGroup, \'id\'>, schemaOptions: { title: \'NewFaqgroup\', exclude: [ \'id\' ] })
 */
export interface NewFaqgroup { 
    groupOrder: number;
    groupName: string;
    groupNameEN: string;
    description: string;
    descriptionEN: string;
}

