/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CountryTR } from './countryTR';


/**
 * (tsType: CountryTRList, schemaOptions: { includeRelations: false })
 */
export interface CountryTRList { 
    countries?: Array<CountryTR>;
}

