/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GenderFilterOrder } from './genderFilterOrder';
import { MyUserIncludeFilterInner } from './myUserIncludeFilterInner';
import { MyUserFields } from './myUserFields';


export interface MyUserFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: GenderFilterOrder;
    where?: { [key: string]: any; };
    fields?: MyUserFields;
    include?: Array<MyUserIncludeFilterInner>;
}

