/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: ItemArchive, schemaOptions: { includeRelations: false })
 */
export interface ItemArchive { 
    id?: number;
    idAuction?: number;
    auctionName?: string;
    itemOrder?: number;
    itemName: string;
    author?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceFinal?: number;
    isWinning?: boolean;
    picUrl?: string;
    last_modified?: string;
}

