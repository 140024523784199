/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemBiddingStatus } from './itemBiddingStatus';
import { Bid } from './bid';


export interface ItemBidRes { 
    status: ItemBiddingStatus;
    availableBids?: Array<Bid>;
}

