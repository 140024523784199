/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionLogFieldsOneOf } from './auctionLogFieldsOneOf';


/**
 * @type AuctionLogFields
 * @export
 */
export type AuctionLogFields = AuctionLogFieldsOneOf | Set<string>;

