/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemPicUrl } from './itemPicUrl';
import { Author } from './author';


/**
 * (tsType: ItemTRWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ItemTRWithRelations { 
    id?: number;
    itemName: string;
    description?: string;
    author?: string;
    size?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    priceFinal?: number;
    startPriceForBidding?: number;
    startPriceForClosing?: number;
    technique?: string;
    signature?: string;
    properties?: string;
    idType?: number;
    type?: string;
    idStatus?: number;
    statusCode?: string;
    status?: string;
    idAuction?: number;
    idAuctionStatus?: number;
    auctionStatusCode?: string;
    auctionStatus?: string;
    auctionArchived?: number;
    hasBid?: boolean;
    itemOrder?: number;
    withoutFee?: boolean;
    isHighlight?: number;
    dating?: string;
    idSignature?: number;
    last_modified?: string;
    bidCount?: number;
    authors?: Array<Author>;
    pics?: Array<ItemPicUrl>;
}

