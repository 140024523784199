/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface NotificationMessagePublic { 
  [key: string]: any | any;


    id: string;
    dateSent: string;
    type: string;
    subject: string;
    contentShort: string;
    contentLong: string;
    channelMail: boolean;
    channelWhatsApp: boolean;
    dateRead?: string;
    wasRead: boolean;
}

