/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemPartialWithRelations } from './itemPartialWithRelations';
import { MyUserPartialWithRelations } from './myUserPartialWithRelations';
import { AuthorLangPartialWithRelations } from './authorLangPartialWithRelations';


/**
 * (tsType: Partial<AuthorWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface AuthorPartialWithRelations { 
    id?: number;
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string | null;
    birthday?: string | null;
    death?: string | null;
    hasProfile?: boolean;
    followable?: boolean;
    canvasId?: number | null;
    followersCount?: number;
    created?: string;
    lang?: AuthorLangPartialWithRelations;
    items?: Array<ItemPartialWithRelations>;
    followers?: Array<MyUserPartialWithRelations>;
    langs?: Array<AuthorLangPartialWithRelations>;
}

