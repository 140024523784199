/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserFieldsOneOf } from './myUserFieldsOneOf';


/**
 * @type MyUserFields
 * @export
 */
export type MyUserFields = MyUserFieldsOneOf | Set<string>;

