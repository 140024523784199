/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: ApprovalRequestWithUser, schemaOptions: { includeRelations: false })
 */
export interface ApprovalRequestWithUser { 
    id?: number;
    idUser: string;
    creationDate?: string;
    campaignCode?: string;
    campaignId?: string;
    approved?: boolean;
    responseDate?: string;
    responseComment?: string;
    firstName?: string;
    lastName?: string;
}

