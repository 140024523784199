import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {SimpleSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {

  public static readonly durationShort = 3000;
  public static readonly durationLong = 9000;
  public static readonly defaultDuration = 6000;
  private defaultAction = 'Zrušit';

  constructor(private matSnackBar: MatSnackBar) {
  }

  setDefaultAction(defaultAction: string): void {
    this.defaultAction = defaultAction;
  }

  open(message: string, duration: number = SnackBarService.defaultDuration): MatSnackBarRef<SimpleSnackBar> {
    return this.matSnackBar.open(message, this.defaultAction, {
      duration
    });
  }

  openShort(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.open(message, SnackBarService.durationShort);
  }

  openLong(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.open(message, SnackBarService.durationLong);
  }
}
