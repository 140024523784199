/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface LoginRes { 
    token?: string;
    name?: string;
    isAdmin?: boolean;
    isApproved?: boolean;
    isReviewer?: boolean;
}

