/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<CountryWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface CountryPartialWithRelations { 
    id?: number;
    description?: string;
    descriptionEN?: string;
    code?: string;
}

