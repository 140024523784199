/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: ItemClosingDecisionInfo, schemaOptions: { includeRelations: false })
 */
export interface ItemClosingDecisionInfo { 
    idMaxBidUser?: string;
    maxBidUserName?: string;
    maxBid?: number;
    idBidUser?: string;
    bidUserName?: string;
    bid?: number;
    idWinningUser?: string;
    winningUserName?: string;
}

