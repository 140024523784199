/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SystemLogData } from './systemLogData';


export interface SystemLog { 
  [key: string]: any | any;


    _time?: string;
    _sysTime?: string;
    _rowId?: string;
    data?: SystemLogData;
}

