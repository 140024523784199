/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';
import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';
import { AuthorLangExcludingIdWithRelations } from './authorLangExcludingIdWithRelations';


/**
 * (tsType: Omit<AuthorWithRelations, \'id\'>, schemaOptions: { title: \'NewAuthor\', includeRelations: true, exclude: [ \'id\' ] })
 */
export interface NewAuthor { 
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string | null;
    birthday?: string | null;
    death?: string | null;
    hasProfile?: boolean;
    followable?: boolean;
    canvasId?: number | null;
    followersCount?: number;
    created?: string;
    lang?: AuthorLangExcludingIdWithRelations;
    items?: Array<ItemExcludingIdWithRelations>;
    followers?: Array<MyUserExcludingIdWithRelations>;
    langs?: Array<AuthorLangExcludingIdWithRelations>;
}

