/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorMinFrontend } from './authorMinFrontend';
import { ItemMinFrontend } from './itemMinFrontend';


export interface UserFavorites { 
    authors?: Array<AuthorMinFrontend>;
    items?: Array<ItemMinFrontend>;
}

