/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemStatusWithRelations } from './itemStatusWithRelations';
import { ItemClosingWithRelations } from './itemClosingWithRelations';
import { MyUserWithRelations } from './myUserWithRelations';
import { ItemBiddingWithRelations } from './itemBiddingWithRelations';
import { ItemPictureWithRelations } from './itemPictureWithRelations';
import { AuctionWithRelations } from './auctionWithRelations';
import { ItemTypeWithRelations } from './itemTypeWithRelations';
import { AuthorWithRelations } from './authorWithRelations';


/**
 * (tsType: ItemWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ItemWithRelations { 
    id?: number;
    itemName: string;
    itemNameEN: string;
    description?: string | null;
    descriptionEN?: string | null;
    author?: string | null;
    size?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    priceFinal?: number;
    startPriceForBidding?: number;
    startPriceForClosing?: number;
    idWinningUser?: string;
    idRepresentativeBidder?: string;
    itemOrder?: number;
    withoutFee?: boolean;
    idAuction?: number;
    isHighlight?: number | null;
    dating?: string;
    datingEN?: string;
    signatureCS?: string | null;
    signatureEN?: string | null;
    propertiesCS?: string | null;
    propertiesEN?: string | null;
    winningEmailSent?: number;
    technique?: string | null;
    techniqueEN?: string | null;
    last_modified?: string;
    canvasId?: number | null;
    likesCount?: number;
    originalCatalogNumber?: number | null;
    idType?: number;
    idStatus?: number;
    winner?: MyUserWithRelations;
    foreignKey?: any | null;
    auction?: AuctionWithRelations;
    Type?: ItemTypeWithRelations;
    Status?: ItemStatusWithRelations;
    authors?: Array<AuthorWithRelations>;
    pictures?: Array<ItemPictureWithRelations>;
    limitations?: Array<ItemBiddingWithRelations>;
    closings?: Array<ItemClosingWithRelations>;
    likedBy?: Array<MyUserWithRelations>;
}

