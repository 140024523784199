/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';
import { NotificationMessageWithRelations } from './notificationMessageWithRelations';


/**
 * (tsType: NotificationMessageRecipientWithRelations, schemaOptions: { includeRelations: true })
 */
export interface NotificationMessageRecipientWithRelations { 
    id?: string;
    dateSent: string;
    channelMail: boolean;
    recipientEmail?: string;
    channelWhatsApp: boolean;
    recipientPhone?: string;
    whatsAppMsgSid?: string;
    dateRead?: string | null;
    wasRead: boolean;
    isHidden: boolean;
    dateHidden?: string | null;
    hiddenByAdmin?: boolean | null;
    messageId?: string;
    recipientId?: string;
    message?: NotificationMessageWithRelations;
    foreignKey?: any | null;
    recipient?: MyUserWithRelations;
}

