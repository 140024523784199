/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface FinContractTemplate { 
    id?: string;
    sortOrder: number;
    title: string;
    titleEN?: string;
    content?: string;
    contentEN?: string;
}

