import {Injectable} from '@angular/core';
// @ts-ignore
import { io } from 'socket.io-client';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveBiddingService {
  socket: any;
  token: string;
  socketId: string;
  connectedUsers: number;

  constructor(private authService: AuthenticationService) {
    this.token = this.authService.currentUser.token;
    this.socketId = null;
  }


  setupSocketConnection(namespace: string): any {
    if (this.socketId === null){
      this.socket = io(namespace, { query: {token: this.token, admin: '1'}, withCredentials: !environment.isLocal });
      console.log('socket.io new connection');
      return {status: true, state: 'conn-new'};
    }else{
      console.log('socket.io is already initialized');
      return {status: true, state: 'conn-exists', data: {connectedUsers: this.connectedUsers}};
    }

  }

  onNewBid(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('bidding', (bid) => {
        observer.next(bid);
      });
    });
  }

  onAucEvent(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('auc-event', (bid) => {
        observer.next(bid);
      });
    });
  }

  onOnlineUsersEvent(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('online-users', (onlineUsers) => {
        this.connectedUsers = onlineUsers;
        observer.next(onlineUsers);
      });
    });
  }

  onReconnection(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('reconnect_attempt', () => {
        observer.next();
      });
    });
  }

  onDisconnect(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('disconnect', () => {
        // this.socketId = null;
        console.log('disconnect');
        observer.next();
      });
    });
  }

  onReconnect(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('reconnect', () => {
        observer.next();
      });
    });
  }

  onConnected(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('connect', () => {
        this.socketId = this.socket.id;
        observer.next();
      });
    });
  }

  // addBid(bid: unknown): void {
  //   this.socket.emit('bidding', 1000);
  // }
}
