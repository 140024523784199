/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<ApprovalRequestWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface ApprovalRequestExcludingIdWithRelations { 
    idUser: string;
    creationDate?: string;
    campaignCode?: string;
    campaignId?: string;
    approved?: boolean;
    responseDate?: string;
    responseComment?: string;
}

