/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: FaqAdmin, schemaOptions: { includeRelations: false })
 */
export interface FaqAdmin { 
    id?: number;
    idFaqGroup: number;
    faqOrder?: number;
    friendlyName: string;
    friendlyNameEN: string;
    question: string;
    questionEN: string;
    answer: string;
    answerEN: string;
    faqGroupName?: string;
}

