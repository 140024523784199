/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface ItemClosing { 
    id?: number;
    idItem: number;
    idUser: string;
    bid: number;
    creationTime?: string;
}

