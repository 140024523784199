import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private authenticationService: AuthenticationService, private fb: UntypedFormBuilder) { }

  loginForm: UntypedFormGroup;
  email: string;
  password: string;
  loading = false;
  submitted = false;
  returnUrl: string;
  ErrMessage: string;

  isAllowedAuth2FA: boolean = environment.auth2FA;

  ngOnInit(): void {

    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      auth2FAToken: ['', this.isAllowedAuth2FA ? Validators.required : ''],
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // tslint:disable-next-line:typedef
  get f() {
    return this.loginForm.controls;
  }

  async onSubmit(): Promise<any>  {

    if (this.loginForm.invalid) {
      console.log('invalid');
      return;
    }
    this.loading = true;
    const loginResponse = await this.authenticationService.login(this.f.email.value, this.f.password.value, this.f.auth2FAToken.value);
    if (loginResponse.bLogin){
      this.submitted = true;
      this.router.navigate([this.returnUrl]);
    }else{
      console.log('Hyr');
      this.loading = false;
      this.ErrMessage = loginResponse.errorMessage;
    }
    this.loading = false;
  }

}
