/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface FinContractTemplateFieldsOneOf { 
    id?: boolean;
    sortOrder?: boolean;
    title?: boolean;
    titleEN?: boolean;
    content?: boolean;
    contentEN?: boolean;
}

