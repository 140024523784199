import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { AuthenticationService} from './../services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private snackBar: SnackBarService, private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {

            if (!this.authenticationService.isLoggedIn) {
              return throwError(err.error.error);
            }
            if (err.status === 401 || err.status === 403) {
                this.authenticationService.logout();
                location.href = '/login';
            }else {
              if (err.status === 402) {
                console.log('Not Found');
                return throwError(err.error.error);
              }
              if (err.error?.error?.message?.sqlMessage){
                this.snackBar.openLong('Chyba: ' + err.error.error.message.sqlMessage);
              }else if (err.error.error.message){
                this.snackBar.openLong('Chyba: ' + err.error.error.message);
              }
              console.log(err);
            }
            return throwError(err);
        }));

    }
}
