/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationMessageRecipientExcludingIdWithRelations } from './notificationMessageRecipientExcludingIdWithRelations';


/**
 * (tsType: Omit<NotificationMessageWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface NotificationMessageExcludingIdWithRelations { 
    dateCreated: string;
    type: string;
    template: string;
    subject: string;
    subjectEN: string;
    contentShort: string;
    contentShortEN: string;
    contentLong: string;
    contentLongEN: string;
    recipients?: Array<NotificationMessageRecipientExcludingIdWithRelations>;
}

