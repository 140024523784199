/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationMessagePartialWithRelations } from './notificationMessagePartialWithRelations';
import { MyUserPartialWithRelations } from './myUserPartialWithRelations';


/**
 * (tsType: Partial<NotificationMessageRecipientWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface NotificationMessageRecipientPartialWithRelations { 
    id?: string;
    dateSent?: string;
    channelMail?: boolean;
    recipientEmail?: string;
    channelWhatsApp?: boolean;
    recipientPhone?: string;
    whatsAppMsgSid?: string;
    dateRead?: string | null;
    wasRead?: boolean;
    isHidden?: boolean;
    dateHidden?: string | null;
    hiddenByAdmin?: boolean | null;
    messageId?: string;
    recipientId?: string;
    message?: NotificationMessagePartialWithRelations;
    foreignKey?: any | null;
    recipient?: MyUserPartialWithRelations;
}

