import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './shared/components/login/login.component';
import {AuthGuard} from './shared/guard/auth.guard';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';
import {QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboard',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'auctions',
        loadChildren: () =>
          import('./auction/auction.module').then((m) => m.AuctionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'authors',
        loadChildren: () =>
          import('./author/author.module').then((m) => m.AuthorModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'closing-console',
        loadChildren: () =>
          import('./closing-console/closing-console.module').then(
            (m) => m.ClosingConsoleModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./news/news.module').then((m) => m.NewsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'why-us',
        loadChildren: () =>
          import('./why-us/why-us.module').then((m) => m.WhyUsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'campaigns',
        loadChildren: () =>
          import('./campaigns/campaigns.module').then((m) => m.CampaignsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'finance',
        loadChildren: () =>
          import('./finance/finance.module').then((m) => m.FinanceModule),
        canActivate: [AuthGuard],
      },
      {
        path: "nas-tym",
        loadChildren: () =>
          import("./team/team.module").then((m) => m.TeamModule),
      },
    ],
  },

  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class RoutingModule {
}
