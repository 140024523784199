/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Author } from './author';


export interface ItemMIN { 
    id?: number;
    itemName: string;
    description?: string;
    author?: string;
    authors?: Array<Author>;
    size?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    priceFinal?: number;
    startPriceForBidding?: number;
    startPriceForClosing?: number;
    technique?: string;
    idType?: number;
    type?: string;
    idStatus?: number;
    statusCode?: string;
    status?: string;
    idAuction?: number;
    idAuctionStatus?: number;
    auctionStatusCode?: string;
    auctionStatus?: string;
    hasBid?: boolean;
    picUrl?: string;
    itemOrder?: number;
    withoutFee?: boolean;
    dating?: string;
    idSignature?: number;
    signature?: string;
    properties?: string | null;
    hasMyBid?: boolean;
    isBiddingWinning?: boolean;
    isWinning?: boolean;
    last_modified?: string;
    bidCount?: number;
    trophyClass?: string;
    isClosingItem?: boolean;
}

