/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface AuctionControllerItem { 
    id: number;
    itemOrder?: number;
    itemName: string;
    author: string;
    size?: string;
    dating?: string;
    technique?: string;
    priceEstimationLow?: number;
    priceEstimationHigh?: number;
    priceCurrent?: number;
    startPriceForBidding?: number;
    priceFinal?: number;
    photoUrl?: string;
    withoutFee?: boolean;
    canvasId?: number;
}

