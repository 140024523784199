/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserDocumentPartialWithRelations } from './userDocumentPartialWithRelations';
import { CountryPartialWithRelations } from './countryPartialWithRelations';
import { ItemPartialWithRelations } from './itemPartialWithRelations';
import { MyUserCredentialsPartialWithRelations } from './myUserCredentialsPartialWithRelations';
import { ApprovalRequestPartialWithRelations } from './approvalRequestPartialWithRelations';
import { AuthorPartialWithRelations } from './authorPartialWithRelations';
import { FinContractVersionPartialWithRelations } from './finContractVersionPartialWithRelations';
import { NotificationMessageRecipientPartialWithRelations } from './notificationMessageRecipientPartialWithRelations';
import { CampaignPartialWithRelations } from './campaignPartialWithRelations';
import { FinContractPartialWithRelations } from './finContractPartialWithRelations';


/**
 * (tsType: Partial<MyUserWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface MyUserPartialWithRelations { 
    id?: string;
    regNumber?: number;
    email?: string;
    titleBefore?: string;
    firstName?: string;
    lastName?: string;
    titleAfter?: string;
    birthday?: string;
    phone?: string;
    street1?: string;
    street2?: string;
    city?: string;
    idCountry?: number;
    zipCode?: string;
    language?: string;
    registrationDate?: string;
    consent1?: boolean;
    consent2?: boolean;
    hasNewsletter?: boolean;
    id1Approved?: boolean;
    id2Approved?: boolean;
    bankAccApproved?: boolean;
    isApproved?: boolean;
    isAdmin?: boolean;
    idGender?: number;
    idGreeting?: number;
    bankAccNumber?: string;
    comment?: string;
    doc1Number?: string;
    doc1ValidTill?: string;
    doc1Issuer?: string;
    doc1IdDocType?: number;
    doc2Number?: string;
    doc2ValidTill?: string;
    doc2Issuer?: string;
    doc2IdDocType?: number;
    idStatus?: number;
    company?: string;
    ic?: string;
    dic?: string;
    isNotPEP?: boolean;
    mailAuctionInfo?: boolean;
    mailAuctionAlert?: boolean;
    notificationSystemWhatsApp?: boolean;
    notificationSystemMail?: boolean;
    notificationAuctionInfoWhatsApp?: boolean;
    notificationAuctionInfoMail?: boolean;
    notificationNewsletterWhatsApp?: boolean;
    notificationNewsletterMail?: boolean;
    notificationFollowedAuthorWhatsApp?: boolean;
    notificationFollowedAuthorMail?: boolean;
    notificationLikedItemWhatsApp?: boolean;
    notificationLikedItemMail?: boolean;
    isReviewer?: boolean;
    canvasId?: number | null;
    allowUploadToCanvas?: boolean;
    fullName?: string | null;
    auth2FAEnabled?: boolean;
    auth2FASecret?: string;
    auth2FAIV?: string;
    country?: CountryPartialWithRelations;
    foreignKey?: any | null;
    userCredentials?: MyUserCredentialsPartialWithRelations;
    userDocuments?: Array<UserDocumentPartialWithRelations>;
    notifications?: Array<NotificationMessageRecipientPartialWithRelations>;
    approvalRequests?: Array<ApprovalRequestPartialWithRelations>;
    finContracts?: Array<FinContractPartialWithRelations>;
    finContractVersions?: Array<FinContractVersionPartialWithRelations>;
    likedItems?: Array<ItemPartialWithRelations>;
    followedAuthors?: Array<AuthorPartialWithRelations>;
    campaigns?: Array<CampaignPartialWithRelations>;
}

