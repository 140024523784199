/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FinContractStateWithRelations } from './finContractStateWithRelations';
import { FinItemWithRelations } from './finItemWithRelations';
import { MyUserWithRelations } from './myUserWithRelations';
import { FinContractVersionWithRelations } from './finContractVersionWithRelations';
import { FinPaymentWithRelations } from './finPaymentWithRelations';


/**
 * (tsType: FinContractWithRelations, schemaOptions: { includeRelations: true })
 */
export interface FinContractWithRelations { 
    id?: string;
    contractNumber?: string;
    otherArrangements?: string;
    createdAt?: string;
    updatedAt?: string;
    dateDeadline: string;
    sumPurchase: number;
    sumRepurchase: number;
    sumPayment: number;
    currentVersion?: number;
    userId?: string;
    contractStateId?: string;
    items?: Array<FinItemWithRelations>;
    payments?: Array<FinPaymentWithRelations>;
    user?: MyUserWithRelations;
    foreignKey?: any | null;
    state?: FinContractStateWithRelations;
    versions?: Array<FinContractVersionWithRelations>;
}

