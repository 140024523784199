/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<ItemClosingWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface ItemClosingPartialWithRelations { 
    id?: number;
    idItem?: number;
    idUser?: string;
    bid?: number;
    creationTime?: string;
}

