/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationTypeFieldsOneOf } from './notificationTypeFieldsOneOf';


/**
 * @type NotificationTypeFields
 * @export
 */
export type NotificationTypeFields = NotificationTypeFieldsOneOf | Set<string>;

