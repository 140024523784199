/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GenderFieldsOneOf } from './genderFieldsOneOf';


/**
 * @type GreetingFields
 * @export
 */
export type GreetingFields = GenderFieldsOneOf | Set<string>;

