/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Author } from './author';


/**
 * (tsType: ArchiveItem, schemaOptions: { includeRelations: false })
 */
export interface ArchiveItem { 
    id: number;
    idAuction: number;
    auctionName: string;
    itemOrder: number;
    itemName: string;
    author: string | null;
    authors?: Array<Author>;
    priceEstimationLow: number;
    priceEstimationHigh: number;
    priceFinal: number;
    priceFinalWithFee: number;
    withoutFee: boolean;
    fee: number;
    isWinning: boolean;
    picUrl: string;
    lastModified: string;
}

