/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FinContractWithRelations } from './finContractWithRelations';


/**
 * (tsType: FinContractStateWithRelations, schemaOptions: { includeRelations: true })
 */
export interface FinContractStateWithRelations { 
    id: string;
    sortOrder: number;
    title: string;
    titleEN?: string;
    contracts?: Array<FinContractWithRelations>;
}

