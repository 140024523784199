import {Component, Inject, LOCALE_ID, Renderer2} from '@angular/core';
import { environment } from '../environments/environment';
import {AuthenticationService} from './shared/services/authentication.service';
import {Router} from '@angular/router';
import {LocalUser} from './shared/models/localUser';
import { Settings } from 'luxon';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {MatIconRegistry, SafeResourceUrlWithIconOptions} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NavigationService} from '../@vex/services/navigation.service';
import {LangsService} from "./shared/services/langs.service";

declare var require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IAP';
  version: string = require( '../../package.json').version;

  baseFrontUrl: string;
  baseAdminUrl: string;
  envName: string;
  opened: boolean;
  env: any;
  profileToken: string;
  profile: LocalUser;

  constructor(public authService: AuthenticationService,
              private navigationService: NavigationService,
              private router: Router,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private platform: Platform,
              private renderer: Renderer2,
              private readonly matIconRegistry: MatIconRegistry,
              private langsService: LangsService,
              private readonly domSanitizer: DomSanitizer,
  ) {

    this.opened = true;
    this.env = environment;
    this.baseFrontUrl = environment.baseFrontUrl;
    this.baseAdminUrl = environment.baseAdminUrl;
    this.envName = environment.name;
    if (authService.isLoggedIn){
      this.profileToken = authService.currentUser.token;
    }
    Settings.defaultLocale = this.localeId;
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/flags/${name}.svg`
            );
          case 'mime':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `/assets/img/icons/mime/${name}.svg`
            );
        }
      }
    );


    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Hlavní',
        children: [
          {
            type: 'link',
            label: 'Přehled',
            route: '/',
            icon: 'mat:dashboard',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'Aukce',
            route: '/auctions',
            icon: 'mat:gavel',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: 'link',
            label: 'Uživatelé',
            route: '/users',
            icon: 'mat:manage_accounts',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: 'link',
            label: 'Autoři',
            route: '/authors',
            icon: 'mat:assignment_ind',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'Obsah',
        children: [
          {
            type: 'link',
            label: 'Novinky',
            route: '/news',
            icon: 'mat:newspaper',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: 'link',
            label: 'Proč Artslimit',
            route: '/why-us',
            icon: 'mat:connect_without_contact',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },

          {
            type: 'link',
            label: 'FAQ',
            route: '/faq',
            icon: 'mat:live_help',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: 'link',
            label: 'Náš tým',
            route: '/nas-tym',
            icon: 'mat:contact_mail',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'Notifikace',
        children: [
          {
            type: 'link',
            label: 'Nová zpráva',
            route: '/notifications/compose',
            icon: 'mat:edit_note',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },

          {
            type: 'link',
            label: 'Šablony',
            route: '/notifications/templates',
            icon: 'mat:drafts',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: 'link',
            label: 'Log',
            route: '/notifications/log',
            icon: 'mat:archive',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'Financování',
        children: [
          {
            type: 'link',
            label: 'Smlouvy',
            route: '/finance',
            icon: 'mat:money',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'Vzory smluv',
            route: '/finance/templates/detail/standard',
            icon: 'mat:drafts',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: true },
          },
          {
            type: 'link',
            label: 'Info',
            route: '/finance/info',
            icon: 'mat:info',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
      {
        type: 'subheading',
        label: 'Ostatní',
        children: [
          {
            type: 'dropdown',
            label: 'Nastavení',
            icon: 'mat:settings',
            availableTo: ['Admin'],
            children: [
              {
                type: 'link',
                label: 'Obecné',
                route: '/settings/general',
                icon: 'mat:linear_scale',
                availableTo: ['Admin'],
                routerLinkActiveOptions: { exact: false },
              },
              {
                type: 'link',
                label: 'FAQ',
                route: '/settings/faq',
                icon: 'mat:linear_scale',
                availableTo: ['Admin'],
                routerLinkActiveOptions: { exact: false },
              },
            ],
          },

          {
            type: 'link',
            label: 'Kampaně',
            route: '/campaigns',
            icon: 'mat:whatshot',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
          {
            type: 'link',
            label: 'Systémový log',
            route: '/settings/system-log',
            icon: 'mat:hub',
            availableTo: ['Admin'],
            routerLinkActiveOptions: { exact: false },
          },
        ],
      },
    ];












  }

  isLoggedIn(): boolean{
    return this.authService.isLoggedIn;
  }

  logout(): void{
    this.authService.logout();
    this.router.navigate(['/login']);
    location.reload();
  }

  openURL(url: string): void {
    window.open(url, '_blank');
  }

}
