/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NewsFieldsOneOf } from './newsFieldsOneOf';


/**
 * @type NewsFields
 * @export
 */
export type NewsFields = NewsFieldsOneOf | Set<string>;

