/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface MyUserFieldsOneOf { 
    id?: boolean;
    regNumber?: boolean;
    email?: boolean;
    titleBefore?: boolean;
    firstName?: boolean;
    lastName?: boolean;
    titleAfter?: boolean;
    birthday?: boolean;
    phone?: boolean;
    street1?: boolean;
    street2?: boolean;
    city?: boolean;
    idCountry?: boolean;
    zipCode?: boolean;
    language?: boolean;
    registrationDate?: boolean;
    consent1?: boolean;
    consent2?: boolean;
    hasNewsletter?: boolean;
    id1Approved?: boolean;
    id2Approved?: boolean;
    bankAccApproved?: boolean;
    isApproved?: boolean;
    isAdmin?: boolean;
    idGender?: boolean;
    idGreeting?: boolean;
    bankAccNumber?: boolean;
    comment?: boolean;
    doc1Number?: boolean;
    doc1ValidTill?: boolean;
    doc1Issuer?: boolean;
    doc1IdDocType?: boolean;
    doc2Number?: boolean;
    doc2ValidTill?: boolean;
    doc2Issuer?: boolean;
    doc2IdDocType?: boolean;
    idStatus?: boolean;
    company?: boolean;
    ic?: boolean;
    dic?: boolean;
    isNotPEP?: boolean;
    mailAuctionInfo?: boolean;
    mailAuctionAlert?: boolean;
    notificationSystemWhatsApp?: boolean;
    notificationSystemMail?: boolean;
    notificationAuctionInfoWhatsApp?: boolean;
    notificationAuctionInfoMail?: boolean;
    notificationNewsletterWhatsApp?: boolean;
    notificationNewsletterMail?: boolean;
    notificationFollowedAuthorWhatsApp?: boolean;
    notificationFollowedAuthorMail?: boolean;
    notificationLikedItemWhatsApp?: boolean;
    notificationLikedItemMail?: boolean;
    isReviewer?: boolean;
    canvasId?: boolean;
    allowUploadToCanvas?: boolean;
    fullName?: boolean;
    auth2FAEnabled?: boolean;
    auth2FASecret?: boolean;
    auth2FAIV?: boolean;
}

