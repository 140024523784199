/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<FinContract>, schemaOptions: { partial: true })
 */
export interface FinContractPartial { 
    id?: string;
    contractNumber?: string;
    otherArrangements?: string;
    createdAt?: string;
    updatedAt?: string;
    dateDeadline?: string;
    sumPurchase?: number;
    sumRepurchase?: number;
    sumPayment?: number;
    currentVersion?: number;
    userId?: string;
    contractStateId?: string;
}

