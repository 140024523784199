/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserPartialWithRelations } from './myUserPartialWithRelations';


/**
 * (tsType: Partial<FinContractVersionWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface FinContractVersionPartialWithRelations { 
    id?: string;
    version?: number;
    filename?: string;
    createdAt?: string;
    content?: string;
    finContractId?: string;
    creatorId?: string;
    creator?: MyUserPartialWithRelations;
    foreignKey?: any | null;
}

