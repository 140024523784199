/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUser } from './myUser';


/**
 * (tsType: SendEmailReq, schemaOptions: { title: \'SendEmailReq\', includeRelations: false })
 */
export interface SendEmailReq { 
    targetType?: number;
    subject?: string;
    subjectEN?: string;
    bodyText?: string;
    bodyTextEN?: string;
    idAuction?: number;
    userType?: number;
    users?: Array<MyUser>;
}

