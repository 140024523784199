/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';
import { FinContractStateExcludingIdWithRelations } from './finContractStateExcludingIdWithRelations';
import { FinContractVersionExcludingIdWithRelations } from './finContractVersionExcludingIdWithRelations';
import { FinItemExcludingIdWithRelations } from './finItemExcludingIdWithRelations';
import { FinPaymentExcludingIdWithRelations } from './finPaymentExcludingIdWithRelations';


/**
 * (tsType: Omit<FinContractWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface FinContractExcludingIdWithRelations { 
    contractNumber?: string;
    otherArrangements?: string;
    createdAt?: string;
    updatedAt?: string;
    dateDeadline: string;
    sumPurchase: number;
    sumRepurchase: number;
    sumPayment: number;
    currentVersion?: number;
    userId?: string;
    contractStateId?: string;
    items?: Array<FinItemExcludingIdWithRelations>;
    payments?: Array<FinPaymentExcludingIdWithRelations>;
    user?: MyUserExcludingIdWithRelations;
    foreignKey?: any | null;
    state?: FinContractStateExcludingIdWithRelations;
    versions?: Array<FinContractVersionExcludingIdWithRelations>;
}

