/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: ItemStatusWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ItemStatusWithRelations { 
    id: number;
    code: string;
    description: string;
    descriptionEN: string;
}

