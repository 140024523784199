/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface NotificationTemplate { 
    id?: number;
    code?: string;
    type: string;
    automaticOnly: boolean;
    description?: string;
    parameters?: string;
    subject: string;
    subjectEN: string;
    contentShort: string;
    contentShortEN: string;
    contentLong: string;
    contentLongEN: string;
    active: boolean;
    approvedWhatsAppTemplate: boolean;
    addPrefixToSubject: boolean;
}

