/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Author } from './author';


/**
 * (tsType: AdminItemClosing, schemaOptions: { includeRelations: false })
 */
export interface AdminItemClosing { 
    id?: number;
    idItem: number;
    idUser: string;
    bid: number;
    creationTime?: string;
    startPriceForBidding: number;
    firstName?: string;
    lastName?: string;
    itemName?: string;
    author?: string;
    authors?: Array<Author>;
    itemOrder?: number;
    isPreClosing?: boolean;
}

