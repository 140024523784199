/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Author } from './author';


/**
 * (tsType: BiddingResult, schemaOptions: { includeRelations: false })
 */
export interface BiddingResult { 
    idItem?: number;
    itemOrder?: number;
    withoutFee?: number;
    itemName?: string;
    author?: string;
    authors?: Array<Author>;
    priceCurrent?: number;
    likesCount?: number;
    idUser?: string;
    firstName?: string;
    lastName?: string;
    startPriceForBidding?: number;
    startPrice?: number;
    maxBid?: number;
    creationTime?: string;
    canvasId?: number;
}

