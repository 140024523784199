/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Partial<FinPayment>, schemaOptions: { partial: true })
 */
export interface FinPaymentPartial { 
    id?: string;
    value?: number;
    note?: string;
    createdAt?: string;
    updatedAt?: string;
    contractId?: string;
    itemId?: string;
    finPaymentTypeId?: string;
}

