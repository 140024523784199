/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: AuctionControllerAuction, schemaOptions: { includeRelations: false })
 */
export interface AuctionControllerAuction { 
    id?: number;
    auctionName: string;
    auctionFrom: string;
    exchangeRate: number;
}

