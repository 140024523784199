import {Component, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {RouterModule} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {UserControllerService} from '../../../../@backend';


@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule, RouterModule, MatDialogModule],
  selector: 'app-2FA-qrcode',
  template: `
    <div class="p-4">
    <h1 mat-dialog-title class="text-center">2FA QR kód</h1>
      <div mat-dialog-content class="flex flex-col place-content-center items-stretch gap-2">
        <p class="text-center">
          <img [src]="this.qrcode" />
        </p>
    </div>
    <div mat-dialog-actions class="flex flex-row items-center place-items-center place-content-center">
      <button mat-flat-button mat-dialog-close>Zavřít</button>
    </div>
</div>
  `,
  styles: [`
  `]
})
export class Qrcode2FAComponent implements OnInit {
  qrcode: string;
  constructor(private userService: UserControllerService) {
    this.get2FAQRCode();
  }
  ngOnInit(): void {
  }
  async get2FAQRCode(): Promise<void> {
    const res = await this.userService.userControllerMy2FAQRCode().toPromise();
    // @ts-ignore
    if (res?.image) {
      // @ts-ignore
      this.qrcode = res.image;
    }
    console.log(this.qrcode);
  }
}
